<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoBrand",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoBrand",
				mrLevel: {},
				filter: {
					status: '',
					type: ''
				},
				mrType : [
					{value : '1', label: 'Wine'},
					{value : '2', label: 'Spirit Liquer'},
					{value : '3', label: 'Water'},
					{value : '4', label: 'Other'}
				]
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
				if(!Object.keys(this.$route.query||{}).length){
					this.filter.status = ''
					this.filter.type = ''
				}
			},
			'filter.level'() {
				this.search()
			},
		},
		methods: {
			type (t){
				if(t == '1'){
					return 'Wine'
				} else if(t == '2'){
					return 'Spirit Liquer'					
				} else if(t == '3'){
					return 'Water'										
				} else if(t == '4'){
					return 'Other'															
				} else {
					return '-'															
				}
			},
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			},
			changeStatus(k,isProduct, active="Active", inactive="Inactive",){
				var v = this.data.data[k]
				var text2 = isProduct == 'Y' ? 'this brand already have product' : ''
				var text = (v.status=="Y"?inactive:active)
				swal({
					title: "Are you sure to "+text+"?",
					text:text2,
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, "+text+"!"],
					// dangerMode: true,
				}).then((ok) => {
					if (ok) {
						v.status = v.status == "Y" ? "N" : "Y"
						BOGen.apirest("/"+this.Name, {type:"status",id:v.id,status:v.status},(err,resp)=>{
							if(err) return swal(err.resp.message,"","warning")
							swal(resp.message,"","success")
						}, "POST")
					}
				});
			},
			deleteItem(e,k){
				var v = this.data.data[k]
				swal({
					title: "Are you sure to delete "+this.ObjectName+"?",
					text:"",
					icon: "warning",
					buttons:{
					delete:{
						text:"Delete",
						value:"delete",
						className:'swal-button--danger',
					},
					deleteItemPermanen:{
						text:'Delete Permanen!',
						value:"deleteItemPermanen",
						className:'swal-button--danger',
					},
					cencelIt:{
						text:'Cancel',
						value:"cencel",
						className:'swal-button--default',
					},
					}
				}).then((value) => {
					switch (value) {

					case "delete":
						v.status = "R"
						BOGen.apirest("/"+this.Name, {type:"delete",id:v.id},(err, resp)=>{
						swal(resp.message, "", "success");
						// this.refreshData()
						setTimeout(()=>{
							$(e.target).parents('tr').removeClass("animated bounceOutRight");
							this.data.data.splice(k, 1);
						}, 1000);
						// $('#'+row).remove()
						}, "POST")
						break;

					case 'deleteItemPermanen':
						v.status = "V"
						BOGen.apirest("/"+this.Name, {type:"deleteItemPermanen",id:v.id},(err, resp)=>{
						swal(resp.message, "", "success");
						// this.refreshData()
						setTimeout(()=>{
							$(e.target).parents('tr').removeClass("animated bounceOutRight");
							this.data.data.splice(k, 1);
						}, 1000);
						// $('#'+row).remove()
						}, "POST")
						break;

					case 'cencelIt':
						return;
						break;

					default:
						break;
					}
				});
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row justify-content-between">
								<div class="col-md-2">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-md-3">
									<select v-model="filter.type" @change="search()" class="form-control"
										placeholder='Type'>
										<option value=""> -- Type --</option>
										<option value="1">Wine</option>
										<option value="2">Spirit Liquer</option>
										<option value="3">Water</option>
										<option value="4">Other</option>
									</select>
								</div>
								<div class="col-md-3">
									<select v-model="filter.status" @change="search()" class="form-control"
										placeholder='Status'>
										<option value=""> -- Filter --</option>
										<option value="L">Dengan Logo</option>
										<option value="T">Tidak Ada Logo</option>
									</select>
								</div>
								<div class="col-md-3 col-lg-4">
									<div class="form-row">
										<div class="col-9">
											<div class="form-group mb-0">
												<div class="input-group">
													<input type="text" v-model="filter.search" class="form-control"
														placeholder="Search...">
													<div class="input-group-append">
														<button class="btn btn-info" type="button" @click="search()"><i
																class="fas fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										<div class="col-3">
											<router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="mb_name"></SortField>
									</th>
									<th>Type</th>
									<th>Logo</th>
									<th>Total Product</th>
									<th>Update At</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mb_name}}</td>
									<td>{{type(v.mb_cat)}}</td>
									<td class="text-center">
		                              	<img v-if="v.mb_logo" :src="uploader(v.mb_logo)" class="w-50-px">
										<span v-else> - </span>
									</td>
									<td>{{v.mb_count||'0'}}</td>
									<td>{{(v.mb_update_at).dates('format2')}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a href="javascript:;" class="icon_action" @click="changeStatus(k,v.isProduct)"
											v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</draggable>
						</table>
					</div>
					<div class="card-body">
						<Pagination :data="data" :limit="3" @pagination-change-page="onPaging" class="justify-content-end"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-6">
							<BoField name="mb_name" v-model="row.mb_name"></BoField>
							<BoField name="mb_link" v-model="row.mb_link"></BoField>
							<BoField name="mb_cat" mandatory :label="'Type'">
								<select2 required="required" name="mb_cat"
									v-bind="validation('mb_cat')" :options="mrType"
									:object="['value','label']"
									v-model="row.mb_cat">
									<option value="">-- Select Type --</option>
								</select2>
							</BoField>
							<BoField name="mb_is_active">
								<div class="row">
									<div class="col-lg-6">
										<div class="row">
											<radio name="mb_is_active" v-model="row.mb_is_active" option="Y"
												:attr="validation('mb_is_active')">Active</radio>
											<radio name="mb_is_active" v-model="row.mb_is_active" option="N">Inactive
											</radio>
										</div>
									</div>
								</div>
							</BoField>
						</div>
						<div class="col-md-3 offset-md-3">
							<BoField name="mb_logo">
								<Uploader :param="{thumbnail:true}" name="mb_logo" v-model="row.mb_logo" type="brand"
									uploadType="cropping"></Uploader>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">Save Brand</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>